import React, { CSSProperties, ElementType, forwardRef, ReactNode, Ref } from 'react'
import classNames from 'classnames'
import styles from './Text.module.scss'

export type TextVariants =
  | 'xs'
  | 'small'
  | 'sm-bold'
  | 'sm'
  | 'smaller-bold'
  | 'md'
  | 'md-bold'
  | 'lg-bold'
  | 'heading'
  | 'heading-big'
  | 'large'

export type TextProps = {
  className?: string
  children?: ReactNode
  variant?: TextVariants
  Component?: ElementType
  style?: CSSProperties
  center?: boolean
}

const Text = (props: TextProps, ref: Ref<any>) => {
  const {
    className = '',
    children = null,
    variant = 'md',
    Component = 'div',
    style,
    center = false,
    ...other
  } = props

  return (
    <Component
      {...other}
      ref={ref}
      style={style}
      className={classNames([
        'text',
        className,
        { [`text-${variant}`]: Boolean(variant), [styles.center]: center },
      ])}
    >
      {children}
    </Component>
  )
}

export default forwardRef(Text)
